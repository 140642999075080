
  import { Vue, Component } from "vue-property-decorator";
  import { cmsApi } from "@/cmsApi";
  import { IPolicyDocument } from "@/interfaces";

  @Component
  export default class Terms extends Vue {
    policyDocument: IPolicyDocument | null = null;

    public async mounted() {
      const resp = await cmsApi.getTermsOfService();
      this.policyDocument = resp.data.items[0];
    }
  }
